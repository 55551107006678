import React from "react"
import { Link } from 'gatsby'; 

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <div style={{height: 750, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
      <h1>NOT FOUND</h1>
      <p>Det kunne vi ikke finde...</p>
      <Link to='/'>Tilbage til forsiden</Link>
    </div>
  </Layout>
)

export default NotFoundPage
